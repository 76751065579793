import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { clearQueryParams } from 'utils';
import { useWindowSize } from 'hooks';
import {
  AgendaSingleDay,
  EventStreamHeader,
  EventNavbar,
  EventSpeakers,
  Footer,
  Intro,
  ProtectedRoute,
  Kiosk,
  PlayingForPatientImpact,
  SEO
} from 'components';
import { FirebaseContext, LocalContext } from 'context';

const dayTemplate = ({ data }) => {
  const { user } = useContext(FirebaseContext);
  const { selectedEvent, setSelectedEvent } = useContext(LocalContext);
  const { frontmatter: day } = data.day;
  const { did } = day;
  const { pathname } = useLocation();
  const { event: eventQueryParam } = queryString.parse(useLocation().search);
  const { windowWidth } = useWindowSize();

  const { events } = day;

  const allKioskEntriesForThisDay = events
    .map((event) => event.kiosk)
    .filter(Boolean)
    .map(([kioskEntry]) => kioskEntry);

  useEffect(() => {
    if (user) {
      if (eventQueryParam) {
        if (did === '2' && eventQueryParam === 'hematology') {
          setSelectedEvent({
            ...events[1],
            did
          });
        } else if (did === '2' && eventQueryParam === 'breast') {
          setSelectedEvent({
            ...events[2],
            did
          });
        } else if (did === '3' && eventQueryParam === 'breast') {
          setSelectedEvent({
            ...events[1],
            did
          });
        } else if (did === '3' && eventQueryParam === 'lung') {
          setSelectedEvent({
            ...events[2],
            did
          });
        } else if (did === '3' && eventQueryParam === 'biosims') {
          setSelectedEvent({
            ...events[3],
            did
          });
        } else if (did === '3' && eventQueryParam === 'gu') {
          setSelectedEvent({
            ...events[4],
            did
          });
        } else {
          setSelectedEvent({
            ...events[0],
            did
          });
        }
      } else {
        setSelectedEvent({
          ...events[0],
          did
        });
      }
      clearQueryParams(pathname);
    }
    return () => {
      if (user) {
        setSelectedEvent(null);
      }
    };
  }, [user, did, eventQueryParam]);

  const tabData = events.map((event) => ({
    colors: event.colors,
    did,
    eid: event.eid,
    title: event.tabTitle
  }));

  return (
    <ProtectedRoute slug={day.slug}>
      {selectedEvent && (
        <>
          <SEO pageSpecificTitle={day.title} />
          <EventNavbar
            colors={selectedEvent.colors}
            eventName={selectedEvent.title}
            slug={day.slug}
            eid={selectedEvent.eid}
          />
          <EventStreamHeader
            colors={selectedEvent.colors}
            dateAndTimeText={selectedEvent.dateAndTimeText}
            description={selectedEvent.description}
            did={selectedEvent.did}
            eid={selectedEvent.eid}
            dbEventTitle={selectedEvent.dbEventTitle}
            endTime={selectedEvent.endTime}
            eventName={selectedEvent.title}
            eventTitle={selectedEvent.title}
            eventSubtitle={selectedEvent.subtitle}
            startTime={selectedEvent.startTime}
            tabData={tabData}
            setSelectedEvent={setSelectedEvent}
            events={events}
            pleaseChooseFranchiseScreenImg={selectedEvent.pleaseChooseFranchiseScreenImg}
            endScreenImg={selectedEvent.endScreenImg}
            isQAndAEnabled={selectedEvent.isQAndAEnabled}
            isPollsEnabled={selectedEvent.isPollsEnabled}
            isParticipantsEnabled={selectedEvent.isParticipantsEnabled}
          />
          {day.did === '1' && selectedEvent.eid === '1' && <PlayingForPatientImpact />}
          <Intro
            did={selectedEvent.did}
            eid={selectedEvent.eid}
            text={selectedEvent.intro.text}
            colors={selectedEvent.colors}
            padding={day.did === '1' && selectedEvent.eid === '1' && '4.2rem 0 7.813rem'}
          />
          {selectedEvent.eid === '1' && (
            <Container>
              <Section>
                <h2>Keynote Speakers</h2>
                <EventSpeakers eventSpeakers={day.speakers} colors={selectedEvent.colors} />
              </Section>
            </Container>
          )}
          {((day.did === '2' && selectedEvent.eid === '2') ||
            (day.did === '3' && selectedEvent.eid === '4')) && (
            <Container>
              <Section>
                <h2>{selectedEvent.speakers?.length > 1 ? 'Guest Speakers' : 'Guest Speaker'}</h2>
                <EventSpeakers
                  eventSpeakers={selectedEvent.speakers}
                  colors={selectedEvent.colors}
                />
              </Section>
            </Container>
          )}
          <AgendaSingleDay
            agenda={selectedEvent.agenda}
            colors={selectedEvent.colors}
            tabTitle={selectedEvent.tabTitle}
            did={did}
            eid={selectedEvent.eid}
            date={`${selectedEvent.dateAndTimeText.split(' ')[0]} ${
              selectedEvent.dateAndTimeText.split(' ')[1]
            }`}
          />
          {((selectedEvent.eid === '1' && allKioskEntriesForThisDay.length > 0) ||
            selectedEvent.kiosk) && (
            <Kiosk
              kiosk={selectedEvent.kiosk || allKioskEntriesForThisDay}
              colors={selectedEvent.colors}
            />
          )}
          <Footer />
        </>
      )}
    </ProtectedRoute>
  );
};

const Section = styled.section`
  grid-column: 1/7;
  h2 {
    color: #3c3c3c;
    font-size: 1.5rem;
    margin-bottom: 1em;
    text-align: center;
    width: 100%;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
  @media (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Container = styled.section`
  align-items: flex-start;
  background-color: rgba(196, 196, 196, 0.2);
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 3rem 1rem;
  ${({ style }) => style}

  @media (min-width: 768px) {
    padding: 6rem 1.25rem;
  }
  @media (min-width: 1150px) {
    padding: 6rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const query = graphql`
  query ($slug: String!) {
    day: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        did
        slug
        title
        speakers {
          speakerOrder
          name
          position
          fbLink
          twLink
          linkedinLink
          emailLink
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        events {
          eid
          title
          subtitle
          tabTitle
          dbEventTitle
          dateAndTimeText
          colors {
            primary
            secondary
            tertiary
          }
          isQAndAEnabled
          isPollsEnabled
          isParticipantsEnabled
          description
          startTime
          endTime
          pleaseChooseFranchiseScreenImg {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          endScreenImg {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          speakers {
            speakerOrder
            name
            position
            twLink
            linkedinLink
            summary
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          intro {
            text
          }
          agenda {
            time {
              jpn
              cet
              ny
            }
            livestream
            speakers
            inset
            duration
            calendarLink
            franchiseColor
          }
          kiosk {
            color
            title
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP, AVIF]
                  width: 572
                  height: 268
                  placeholder: TRACED_SVG
                )
              }
            }
            banner {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP, AVIF]
                  width: 1164
                  height: 420
                  placeholder: TRACED_SVG
                )
              }
            }
            excerpt
            calendarLink
            content
            videos {
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    formats: [AUTO, WEBP, AVIF]
                    width: 453
                    height: 268
                    placeholder: TRACED_SVG
                  )
                }
              }
              title
              url
            }
          }
        }
      }
    }
  }
`;

export default dayTemplate;
